.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.layout-top-nav-no {
  margin-top: 71px !important;
  height: 50px !important;
  line-height: 50px !important;
}
.app-content.layout-top-nav {
  margin-top: 125px!important;
  min-height: calc(100vh - 130px - 55px)!important;
}
.page-header-app{
    max-width: 1400px;
    margin: auto!important;
    width: 100%;
    padding: 25px;

}
.w-100-p{
  width: 100%!important;
}
.pdpd_loading_warp{
  position: absolute;
  margin: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #ffffffd1;
  top: 0;
  bottom: 0;
}
.pdpd_loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.hocrpdf{
  width: 100%;
  border: 0px;
  height: 1200px;
}